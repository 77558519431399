<template>
    <div>
      <h1>Einstellungen</h1>
      <v-row class="ma-2">
        <v-col cols="12">
            <v-text-field label="Arbeitsstunden / Tag" v-model.number="$root.settings.hoursPerDay"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Stundensatz aktuell" v-model.number="$root.settings.hourlyWage"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Zuschlag Fremdleistungen" v-model.number="$root.settings.additionExternalServices"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Verwaltungskosten für Fremdleistungen" v-model.number="$root.settings.administrativeExpensesExternalServices"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="MwSt." v-model.number="$root.settings.vatRate"></v-text-field>
        </v-col>
        <v-col cols="12">
          <div class="text-h6">Material-Aufschäge</div>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Innenputz" v-model.number="$root.settings.additionsMaterial[0]"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Außenputz" v-model.number="$root.settings.additionsMaterial[1]"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Trockenbau" v-model.number="$root.settings.additionsMaterial[2]"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Gerüst" v-model.number="$root.settings.additionsMaterial[3]"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Dämmung" v-model.number="$root.settings.additionsMaterial[4]"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Sonstige" v-model.number="$root.settings.additionsMaterial[5]"></v-text-field>
        </v-col>
      </v-row>
    </div>
</template>

<script>
export default {
  name: "Settings"
}
</script>

<style scoped>

</style>
